<template>
  <div class="building">
    <SeparatorTitle :separator="false" :title="$route.name" />
    <SubMenu :items="items" />
  </div>
</template>

<script>
import SubMenu from "../../components/menu/SubMenu.vue";
import SeparatorTitle from "../../widgets/title/SeparatorTitle.vue";
import store from "../../store";
import { onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { subMenu } from "../../constants";

export default {
  components: { SubMenu, SeparatorTitle },
  setup() {
    const route = useRoute();
    const items = subMenu.building
      .filter((item) => store.state.auth.modules.includes(item.label) && item)
      .map((item) => {
        const badge = store.state.auth.notifications.filter(
          (el) => el.typeId === item.notificationTypeId
        ).length;
        return { ...item, badge };
      });

    onBeforeMount(() => {
      const breadcrumbs = [
        { label: "Inicio", to: "/home" },
        { label: route.name, to: route.path },
      ];
      store.commit("setBreadcrumbs", breadcrumbs);
    });

    return { items };
  },
};
</script>

<style scoped>
.building {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
